<template lang="html">
  <div class="content">
    <div class="header">
      <h1>Log in or Sign up</h1>
    </div>
    <div class="form">
      <div id="firebaseui-auth-container">
      </div>
    </div>
  </div>
</template>

<script>
import { ui, uiConfig } from '../firebase';

// call firebase ui auth to handle login
export default {
  name: 'auth',
  mounted() {
    ui.start('#firebaseui-auth-container', uiConfig);
    },
}
</script>

<style lang="css" scoped>
.content {
    justify-content: center;
  }
</style>
